import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const Hypothesis = (props) => {
  const { value, index, setHypotheses, setEvidence } = props;
  const [editing, setEditing] = useState(false);
  const [newValue, setNewValue] = useState(value);

  // Update newValue if value changes (this will happen if data is imported for
  // instance)
  useEffect(() => {
    setNewValue(value);
  }, [value]);

  const toggleEditing = () => setEditing((curr) => !curr);
  const handleNewValueChange = (event) => {
    setNewValue(event.target.value);
  };
  const handleSaveButtonClick = () => {
    toggleEditing();
    setHypotheses((current) => {
      let newHypotheses = [...current];
      newHypotheses[index] = newValue;
      return newHypotheses;
    });
  };
  const handleCancelButtonClick = () => {
    toggleEditing();
    setNewValue(value);
  };
  const handleDeleteButtonClick = () => {
    toggleEditing();
    setHypotheses((current) => {
      // Remove this hypothesis from the list of hypotheses
      let newHypotheses = [...current];
      newHypotheses.splice(index, 1);

      // Remove ratings for this hypothesis
      setEvidence((currentEvidence) => {
        let newEvidence = currentEvidence.map((e) => {
          let newPieceOfEvidence = { ...e };
          newPieceOfEvidence.consistencyRatings.splice(index, 1);
          return newPieceOfEvidence;
        });
        return newEvidence;
      });

      return newHypotheses;
    });
  };

  const hypothesisId = `H${index + 1}`;
  if (!editing) {
    return (
      <dl className="f5 lh-title mv2" onClick={toggleEditing}>
        <dt className="dib b">{hypothesisId}:</dt>
        <dd className="dib ml1">{value}</dd>
      </dl>
    );
  } else {
    return (
      <div className="mb2">
        <input
          className="input-reset ba b--black-20 pa2 mb2 db w-100"
          value={newValue}
          onChange={handleNewValueChange}
        />

        <a
          className="f6 link dim br2 ph3 pv2 mb2 dib white bg-red ml2"
          href="#0"
          onClick={handleCancelButtonClick}
        >
          cancel
        </a>

        <a
          className="f6 link dim br2 ph3 pv2 mb2 dib white bg-near-black ml2"
          href="#0"
          onClick={handleSaveButtonClick}
        >
          save
        </a>

        <a
          className="f6 link dim br2 ph3 pv2 mb2 dib white bg-red ml2 fr"
          href="#0"
          onClick={handleDeleteButtonClick}
        >
          delete
        </a>
      </div>
    );
  }
};

HypothesisList.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  setHypotheses: PropTypes.func,
  setEvidence: PropTypes.func,
};
export default function HypothesisList(props) {
  const { values, setHypotheses, setEvidence } = props;

  const hypotheses = values.map((value, i) => {
    // Should IDs be 0-indexed or 1-indexed?
    // Going with 1-indexed for now to copy the textbook example.
    return (
      <Hypothesis
        key={i}
        value={value}
        index={i}
        setHypotheses={setHypotheses}
        setEvidence={setEvidence}
      />
    );
  });

  return (
    <>
      <h4 className="f5 fw6">Hypotheses:</h4>
      {hypotheses}
    </>
  );
}
