import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

EvidenceTableRow.propTypes = {
  name: PropTypes.string,
  evidenceId: PropTypes.string,
  consistencyRatings: PropTypes.arrayOf(
    PropTypes.oneOf(["I", "II", "N", "C", "CC"])
  ),
  credibility: PropTypes.oneOf(["Low", "Medium", "High"]),
  relevance: PropTypes.oneOf(["Low", "Medium", "High"]),
  setEvidence: PropTypes.func,
};
export default function EvidenceTableRow(props) {
  const {
    name,
    evidenceId,
    consistencyRatings,
    credibility,
    relevance,
    setEvidence,
  } = props;

  const [nameEditMode, setNameEditMode] = useState(false);
  const [newName, setNewName] = useState(name);

  // Update newName if name changes (this will happen if data is imported for
  // instance)
  useEffect(() => {
    setNewName(name);
  }, [name]);

  const onRatingChange = (event, ratingIndex) => {
    const newRating = event.target.value;
    setEvidence((current) => {
      const oldPieceOfEvidenceIndex = current.findIndex((e) => e.name === name);
      const oldPieceOfEvidence = current[oldPieceOfEvidenceIndex];

      // Update rating.
      let newConsistencyRatings = [...oldPieceOfEvidence.consistencyRatings];
      newConsistencyRatings[ratingIndex] = newRating;

      const newPieceOfEvidence = {
        ...oldPieceOfEvidence,
        consistencyRatings: newConsistencyRatings,
      };

      let newEvidence = [...current];
      newEvidence[oldPieceOfEvidenceIndex] = newPieceOfEvidence;

      return newEvidence;
    });
  };

  const onCredibilityChange = (event) => {
    const newCredibility = event.target.value;
    setEvidence((current) => {
      const oldPieceOfEvidenceIndex = current.findIndex((e) => e.name === name);
      const oldPieceOfEvidence = current[oldPieceOfEvidenceIndex];

      // Update credibility
      const newPieceOfEvidence = {
        ...oldPieceOfEvidence,
        credibility: newCredibility,
      };

      let newEvidence = [...current];
      newEvidence[oldPieceOfEvidenceIndex] = newPieceOfEvidence;

      return newEvidence;
    });
  };

  const onRelevanceChange = (event) => {
    const newRelevance = event.target.value;
    setEvidence((current) => {
      const oldPieceOfEvidenceIndex = current.findIndex((e) => e.name === name);
      const oldPieceOfEvidence = current[oldPieceOfEvidenceIndex];

      // Update relevance
      const newPieceOfEvidence = {
        ...oldPieceOfEvidence,
        relevance: newRelevance,
      };

      let newEvidence = [...current];
      newEvidence[oldPieceOfEvidenceIndex] = newPieceOfEvidence;

      return newEvidence;
    });
  };

  const ratingCells = consistencyRatings.map((rating, index) => {
    return (
      <td key={index} className="pv3 pr3 bb b--black-20 tc">
        <select
          value={rating}
          onChange={(event) => onRatingChange(event, index)}
          readOnly
        >
          <option value="I">I</option>
          <option value="II">II</option>
          <option value="N">N</option>
          <option value="C">C</option>
          <option value="CC">CC</option>
        </select>
      </td>
    );
  });

  let nameElement = (
    <span onClick={() => setNameEditMode((curr) => !curr)}>
      {evidenceId + " " + name}
    </span>
  );
  if (nameEditMode) {
    nameElement = (
      <>
        <input
          className="input-reset ba b--black-20 pa2 mb2 db w-90"
          value={newName}
          onChange={(event) => {
            setNewName(event.target.value);
          }}
        ></input>

        <a
          className="f6 link dim br2 ph3 pv2 mb2 dib white bg-red ml2"
          href="#0"
          onClick={() => {
            setNameEditMode((curr) => !curr);
            setNewName(name);
          }}
        >
          cancel
        </a>

        <a
          className="f6 link dim br2 ph3 pv2 mb2 dib white bg-near-black ml2"
          href="#0"
          onClick={() => {
            setEvidence((current) => {
              const oldPieceOfEvidenceIndex = current.findIndex(
                (e) => e.name === name
              );
              const oldPieceOfEvidence = current[oldPieceOfEvidenceIndex];

              // Update name
              const newPieceOfEvidence = {
                ...oldPieceOfEvidence,
                name: newName,
              };

              let newEvidence = [...current];
              newEvidence[oldPieceOfEvidenceIndex] = newPieceOfEvidence;

              return newEvidence;
            });
            setNameEditMode(false);
          }}
        >
          save
        </a>

        <a
          className="f6 link dim br2 ph3 pv2 mb2 dib white bg-red ml2 fr"
          href="#0"
          onClick={() => {
            setEvidence((current) => {
              const oldPieceOfEvidenceIndex = current.findIndex(
                (e) => e.name === name
              );

              let newEvidence = [...current];
              newEvidence.splice(oldPieceOfEvidenceIndex, 1);
              return newEvidence;
            });
            setNameEditMode(false);
          }}
        >
          delete
        </a>
      </>
    );
  }

  return (
    <tr>
      <td className="pv3 pr3 bb b--black-20 tl">{nameElement}</td>
      <td className="pv3 pr3 bb b--black-20 tc">
        <select value={credibility} onChange={onCredibilityChange}>
          <option value="Low">Low</option>
          <option value="Medium">Medium</option>
          <option value="High">High</option>
        </select>
      </td>
      <td className="pv3 pr3 bb b--black-20 tc">
        <select value={relevance} onChange={onRelevanceChange}>
          <option value="Low">Low</option>
          <option value="Medium">Medium</option>
          <option value="High">High</option>
        </select>
      </td>
      {ratingCells}
    </tr>
  );
}
