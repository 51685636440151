import { useState, useEffect } from "react";
import PropTypes from "prop-types";

Title.propTypes = {
  title: PropTypes.string,
  setTitle: PropTypes.func,
};
export default function Title(props) {
  const { title, setTitle } = props;
  const [editing, setEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(title || "[Click to set title]");

  // Update newTitle if title changes (this will happen if data is imported for
  // instance)
  useEffect(() => {
    setNewTitle(title);
  }, [title]);

  const toggleEditing = () => setEditing((current) => !current);
  const handleNewTitleChange = (event) => {
    setNewTitle(event.target.value);
  };
  const handleCancelButtonClick = () => {
    setEditing(false);
    setNewTitle(title);
  };
  const handleSaveButtonClick = () => {
    setEditing(false);
    setTitle(newTitle);
  };

  if (!editing) {
    return (
      <h2
        className="f3 lh-copy measure"
        style={{ cursor: "text" }}
        onClick={toggleEditing}
      >
        {title || "[Click to set title]"}
      </h2>
    );
  } else {
    return (
      <>
        <input
          className="input-reset ba b--black-20 pa2 mb2 db w-100"
          value={newTitle}
          onChange={handleNewTitleChange}
        />
        <a
          className="f6 link dim br2 ph3 pv2 mb2 dib white bg-red ml2"
          href="#0"
          onClick={handleCancelButtonClick}
        >
          cancel
        </a>
        <a
          className="f6 link dim br2 ph3 pv2 mb2 dib white bg-near-black ml2"
          href="#0"
          onClick={handleSaveButtonClick}
        >
          save
        </a>
      </>
    );
  }
}
