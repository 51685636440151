import Papa from "papaparse";

/**
 * Converts title, hypotheses, and evidence into a CSV string
 * @param {string} title
 * @param {string[]} hypotheses
 * @param {object[]} evidence
 * @returns {string}
 */
export function toCsv(title, hypotheses, evidence) {
  // The number of columns is equal to the number of hypotheses + 1 (relevance) + 1 (credibility) + 1 (evidence name)
  const numberOfColumns = hypotheses.length + 3;
  // The number of rows is equal to the number of evidence + 1 (evidence header)
  //   + 1 (title)
  //   + 1 (hypotheses header)
  //   + (number of hypotheses)
  const numberOfRows = 3 + evidence.length + hypotheses.length;

  let outputMatrix = Array(numberOfRows)
    .fill("")
    .map(() => Array(numberOfColumns).fill(""));

  let cursor = 0;

  // Add title
  outputMatrix[cursor++][0] = `"${title}"`;

  // Add hypotheses
  outputMatrix[cursor++][0] = "Hypotheses";
  for (let i = 0; i < hypotheses.length; i++) {
    outputMatrix[cursor++][0] = `"${hypotheses[i]}"`;
  }

  // Add evidence header
  outputMatrix[cursor][0] = "Evidence";
  outputMatrix[cursor][1] = "Credibility";
  outputMatrix[cursor][2] = "Relevance";
  for (let i = 0; i < hypotheses.length; i++) {
    outputMatrix[cursor][i + 3] = `H${i + 1}`;
  }
  cursor += 1;

  // Add evidence
  evidence.forEach((e) => {
    outputMatrix[cursor][0] = `"${e.name}"`;
    outputMatrix[cursor][1] = e.credibility;
    outputMatrix[cursor][2] = e.relevance;
    outputMatrix[cursor] = [
      ...outputMatrix[cursor].slice(0, 3),
      ...e.consistencyRatings,
    ];
    cursor += 1;
  });

  // Transform everything into a CSV string
  const csvRows = outputMatrix.map((arr) => {
    return arr.join(",");
  });
  const csvString = csvRows.join("\n");

  return csvString;
}

/**
 * Parses out title, hypotheses, and evidence from a properly formatted CSV.
 * TODO: add validation
 * @param {string} csvString
 * @returns {object} result
 * @returns {string} result.title
 * @returns {string[]} result.hypotheses
 * @returns {object[]} result.evidence
 */
export function fromCsv(csvString) {
  const parsedCsv = Papa.parse(csvString).data;
  let cursor = 0;

  const title = parsedCsv[cursor++][0];
  cursor += 1; // skip "Hypotheses" header

  let hypotheses = [];
  while (parsedCsv[cursor][0] !== "Evidence") {
    hypotheses.push(parsedCsv[cursor++][0]);
  }
  cursor += 1; // Skip "Evidence, Credibility,..." header

  let evidence = [];
  for (let i = cursor; i < parsedCsv.length; i++) {
    const [name, credibility, relevance, ...ratings] = parsedCsv[i];
    const pieceOfEvidence = {
      name,
      credibility,
      relevance,
      consistencyRatings: ratings,
    };
    evidence.push(pieceOfEvidence);
  }

  return { title, hypotheses, evidence };
}
