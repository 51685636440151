import React from "react";
import PropTypes from "prop-types";
import EvidenceTableRow from "./EvidenceTableRow";

EvidenceTable.propTypes = {
  hypotheses: PropTypes.arrayOf(PropTypes.string),
  evidence: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      consistencyRatings: PropTypes.arrayOf(
        PropTypes.oneOf(["I", "II", "N", "C", "CC"])
      ),
      credibility: PropTypes.oneOf(["Low", "Medium", "High"]),
      relevance: PropTypes.oneOf(["Low", "Medium", "High"]),
    })
  ),
  setEvidence: PropTypes.func,
};
export default function EvidenceTable(props) {
  const { hypotheses, evidence, setEvidence } = props;

  const handleAddEvidenceClick = () => {
    setEvidence((current) => {
      return [
        ...current,
        {
          name: "New Evidence",
          consistencyRatings: hypotheses.map((_) => "N"),
          credibility: "Medium",
          relevance: "Medium",
        },
      ];
    });
  };

  // Generate table headers
  const tableHeaderNames = [...hypotheses];
  const tableHeaders = tableHeaderNames.map((name, i) => {
    const hypothesisId = `H${i + 1}`;
    return (
      <th
        key={i}
        className="fw6 bb b--black-20 tl pb3 pr3 bg-white tc"
        title={name}
        style={{ cursor: "help" }}
      >
        {hypothesisId}
      </th>
    );
  });

  // Generate table rows for evidence and consistency ratings.
  const tableRows = evidence.map((e, i) => {
    const { name, consistencyRatings, credibility, relevance } = e;
    const evidenceId = `E${i + 1}:`;
    return (
      <EvidenceTableRow
        key={evidenceId}
        evidenceId={evidenceId}
        name={name}
        consistencyRatings={consistencyRatings}
        credibility={credibility}
        relevance={relevance}
        setEvidence={setEvidence}
      />
    );
  });

  // An array of zeroes with length equal to the number of hypotheses
  const initialScores = Array(hypotheses.length).fill(0);

  // Compute inconsistency scores
  const inconsistencyScores = evidence.reduce(
    (acc, curr) => {
      curr.consistencyRatings.forEach((rating, index) => {
        if (rating === "I") {
          acc[index] -= 1;
        } else if (rating === "II") {
          acc[index] -= 2;
        }
      });
      return acc;
    },
    [...initialScores]
  );
  //console.log(inconsistencyScores);
  const leastInconsistent = Math.max(...inconsistencyScores);
  const mostInconsistent = Math.min(...inconsistencyScores);
  const inconsistencyScoreCells = inconsistencyScores.map((score, index) => {
    let extraStyle = "";
    if (score === leastInconsistent) {
      extraStyle = "green";
    } else if (score === mostInconsistent) {
      extraStyle = "red";
    }
    return (
      <td key={index} className={`pv3 pr3 bb b--black-20 tc b ${extraStyle}`}>
        {score}
      </td>
    );
  });

  // Compute weighted inconsistency scores
  const wInconsistencyScores = evidence.reduce(
    (acc, curr) => {
      const { credibility, relevance, consistencyRatings } = curr;
      // Compute inconsistency scores
      consistencyRatings.forEach((rating, index) => {
        let weight = 1;

        // If both the Credibility and Relevance are High, the value of the
        // Inconsistent rating is doubled.
        if (credibility === "High" && relevance === "High") {
          weight = 2;
        }

        // If both Credibility and Relevance are Low, the value of an
        // Inconsistent or Very Inconsistent rating is cut in half to .5 and 1,
        // respectively.
        if (credibility === "Low" && relevance === "Low") {
          weight = 0.5;
        }

        if (rating === "I") {
          acc[index] -= 1 * weight;
        } else if (rating === "II") {
          acc[index] -= 2 * weight;
        }
      });

      return acc;
    },
    [...initialScores]
  );
  // console.log(wInconsistencyScores);
  const wLeastInconsistent = Math.max(...wInconsistencyScores);
  const wMostInconsistent = Math.min(...wInconsistencyScores);
  const wInconsistencyScoreCells = wInconsistencyScores.map((score, index) => {
    let extraStyle = "";
    if (score === wLeastInconsistent) {
      extraStyle = "green";
    } else if (score === wMostInconsistent) {
      extraStyle = "red";
    }
    return (
      <td key={index} className={`pv3 pr3 bb b--black-20 tc b ${extraStyle}`}>
        {score}
      </td>
    );
  });

  return (
    <div className="overflow-auto mt4 mh0-l">
      <table className="f5 w-100 mw8 center" cellSpacing="0">
        <thead>
          <tr>
            <th className="fw6 bb b--black-20 tl pb3 pr3 bg-white">Evidence</th>
            <th className="fw6 bb b--black-20 tc pb3 pr3 bg-white">
              Credibility
            </th>
            <th className="fw6 bb b--black-20 tc pb3 pr3 bg-white">
              Relevance
            </th>
            {tableHeaders}
          </tr>
        </thead>
        <tbody className="lh-copy">
          {tableRows}
          <tr>
            <td className="pv3 pr3 bb b--black-20 b">Inconsistency Scores</td>
            <td className="pv3 pr3 bb b--black-20"></td>
            <td className="pv3 pr3 bb b--black-20"></td>
            {inconsistencyScoreCells}
          </tr>
          <tr>
            <td className="pv3 pr3 bb b--black-20 b">
              Weighted Inconsistency Scores
            </td>
            <td className="pv3 pr3 bb b--black-20"></td>
            <td className="pv3 pr3 bb b--black-20"></td>
            {wInconsistencyScoreCells}
          </tr>
        </tbody>
      </table>

      <a
        className="f6 link dim br2 ph3 pv2 mb2 dib white bg-near-black mt3"
        href="#0"
        onClick={handleAddEvidenceClick}
      >
        Add evidence
      </a>
    </div>
  );
}
