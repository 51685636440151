import { useState, useRef } from "react";
import { saveAs } from "file-saver";

import HypothesisList from "./components/HypothesisList";
import EvidenceTable from "./components/EvidenceTable";
import Title from "./components/Title";
import { fromCsv, toCsv } from "./utilities/serialization";

export default function App() {
  const [title, setTitle] = useState(
    "Will Iraq Retaliate for US Bombing of Its Intelligence Headquarters?"
  );
  const [hypotheses, setHypotheses] = useState([
    "Iraq will not retaliate",
    "It will sponsor some minor terrorist actions.",
    "Iraq is planning a major terrorist attack, perhaps against one or more CIA installations.",
  ]);
  const [evidence, setEvidence] = useState([
    {
      name: "Saddam public statement of intent not to retaliate.",
      consistencyRatings: ["C", "C", "C"],
      credibility: "Low",
      relevance: "Medium",
    },
    {
      name: "Absense of terrorist offensive during the 1991 Gulf War.",
      consistencyRatings: ["C", "C", "I"],
      credibility: "Low",
      relevance: "Low",
    },
    {
      name: "Assumption that Iraq would not want to provoke another US attack.",
      consistencyRatings: ["C", "C", "I"],
      credibility: "Medium",
      relevance: "Medium",
    },
    {
      name:
        "Increase in frequency/length of monitored Iraqi agent radio broadcasts.",
      consistencyRatings: ["I", "C", "C"],
      credibility: "High",
      relevance: "Medium",
    },
    {
      name:
        "Iraqi embassies instructed to take increased security precautions.",
      consistencyRatings: ["I", "C", "C"],
      credibility: "High",
      relevance: "Medium",
    },
    {
      name:
        "Assumption that failure to retaliate would be unacceptable loss of face for Saddam.",
      consistencyRatings: ["II", "C", "C"],
      credibility: "High",
      relevance: "High",
    },
  ]);
  const fileInputRef = useRef(null);

  const handleAddHypothesisClick = () => {
    setHypotheses((current) => {
      setEvidence((currentEvidence) => {
        let newEvidence = currentEvidence.map((e) => {
          let newPieceOfEvidence = { ...e };
          newPieceOfEvidence.consistencyRatings.push("N");
          return newPieceOfEvidence;
        });
        return newEvidence;
      });

      return [...current, "New hypothesis"];
    });
  };

  const handleClearButtonClick = () => {
    setTitle("[Click to set title]");
    setHypotheses([]);
    setEvidence([]);
  };

  const handleResetButtonClick = () => {
    window.location.reload();
  };

  const handleExportButtonClick = () => {
    const exportCsv = toCsv(title, hypotheses, evidence);
    const blob = new Blob([exportCsv], { type: "text/plain;charset=utf-8" });
    saveAs(blob, `${title}.csv`);
  };

  // File uploads are handled by a hidden <input>. When the user clicks on
  // "Upload file", the hidden <input> is clicked and a native file picker appears.
  // When a file is selected, onFileUpload() will be called.
  const onFileUpload = (e) => {
    const { files } = e.target;
    const file = files[0];

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const readFile = reader.result;
      // TODO: Add validation
      const importedData = fromCsv(readFile);
      // NOTE: The following results in:
      //   index.js:1 Warning: Received NaN for the `children` attribute. If this is expected, cast the value to a string.
      // Not 100% sure why. I think I may need to refactor this mess before I can add import/export
      setTitle(importedData.title);
      setHypotheses(importedData.hypotheses);
      setEvidence(importedData.evidence);

      // Reset (necessary to avoid a bug where the same file uploaded twice
      // does nothing)
      // https://stackoverflow.com/a/42192710/6591491
      e.target.value = null;
    });

    reader.readAsText(file);
  };
  const handleImportButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div
      className="helvetica pa3 ma3"
      style={{ margin: "0 auto", maxWidth: "960px" }}
    >
      <h1 className="f2 lh-title">ACH</h1>
      <p className="f5 lh-copy measure">
        This is a tool to assist in the use of the{" "}
        <a
          className="link"
          href="https://www.cia.gov/library/center-for-the-study-of-intelligence/csi-publications/books-and-monographs/psychology-of-intelligence-analysis/art11.html"
        >
          Analysis of Competing Hypotheses
        </a>{" "}
        technique.
      </p>
      <p className="f5 lh-copy measure">
        Get started by editing the example below or click the red clear button
        to start with an empty template.
      </p>
      <div className="mb3">
        <a
          className="f6 link dim br2 ph3 pv2 mb2 dib white bg-near-black"
          href="#0"
          onClick={handleImportButtonClick}
        >
          import csv
        </a>
        <a
          className="f6 link dim br2 ph3 pv2 mb2 dib white bg-near-black ml2"
          href="#0"
          onClick={handleExportButtonClick}
        >
          export csv
        </a>
        <a
          className="f6 link dim br2 ph3 pv2 mb2 dib white bg-red fr ml2"
          href="#0"
          onClick={handleClearButtonClick}
        >
          clear
        </a>
        <a
          className="f6 link dim br2 ph3 pv2 mb2 dib white bg-red fr"
          href="#0"
          onClick={handleResetButtonClick}
        >
          reset
        </a>
      </div>

      <Title title={title} setTitle={setTitle} />

      <HypothesisList
        values={hypotheses}
        setHypotheses={setHypotheses}
        setEvidence={setEvidence}
      />
      <a
        className="f6 link dim br2 ph3 pv2 mb2 dib white bg-near-black mt1"
        href="#0"
        onClick={handleAddHypothesisClick}
      >
        Add hypothesis
      </a>

      <EvidenceTable
        hypotheses={hypotheses}
        evidence={evidence}
        setEvidence={setEvidence}
      />
      <br />

      <input
        type="file"
        ref={fileInputRef}
        onChange={onFileUpload}
        style={{
          visibility: "hidden",
          display: "none",
          position: "absolute",
          height: 0,
        }}
      />
    </div>
  );
}
